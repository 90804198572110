@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter;
  }
}

.scroll-container {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

.highlight-text-anim {
  @apply bg-gradient-to-r from-[#b4befe] to-[#fab387] animate-gradient bg-300% bg-clip-text text-transparent;
}
.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;

  height: 2px;
  bottom: 0;
  left: 0;
  @apply bg-gradient-to-r from-[#b4befe] to-[#fab387] animate-gradient bg-300%;
}
